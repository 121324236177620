<template>
  <div v-if="user" class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Consumers</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Home</li>
              <li class="breadcrumb-item active">Consumers</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Default box -->
      <div class="card card-solid" style="margin-bottom: 0 !important;">
        <Loader v-if="loading"  style="height: 100%"  />
        <div class="card-header">
          <div class="col-sm-12 col-md-12">
          <div class="card-tools">
            <div class="input-group input-group-sm">
              <div class="input-group mb-0">
                <div class="input-group-prepend">
                  <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{this.by_value}}</button>
                  <button title="Sort A-Z" v-if="sort_value!='asc'" @click.prevent="setSort('asc')" type="button" class="btn btn-default d-none d-md-block"><i class="fa fa-sort-alpha-asc"></i></button>
                  <button title="Sort Z-A (Descending)" v-else @click.prevent="setSort('desc')" type="button" class="btn btn-default d-none d-md-block"><i class="fa fa-sort-alpha-desc"></i></button>
                  <div class="dropdown-menu">
                    <li class="dropdown-item ddown" @click="setBy('Consumer Number')">Consumer No.</li>
                    <li class="dropdown-divider"></li>
                    <li class="dropdown-item ddown" @click="setBy('Last Name')">Last Name</li>
                    <li class="dropdown-item ddown" @click="setBy('First Name')">First Name</li>
                  </div>
                </div>
                <input ref="search" @keydown.enter="getResults" v-model="search_value" type="text" class="form-control" :placeholder="('Search by ' + this.by_value)">
                <div class="input-group-append">
                  <button title="Search" @click.prevent="getResults" type="button" class="btn btn-default"><i v-if="loading" class="spinner-border spinner-border-sm"></i><i v-else class="fa fa-search"></i></button>
                </div>
               <button :disabled="user.role === 'Restricted'" class="btn btn-default d-none d-md-block ml-2" @click="addConsumer"><i  class="fa fa-plus"></i> New</button>
              </div>
            </div>

            <div class="row mt-3 d-md-none">
              <div class="col">
                <button title="Sort A-Z" v-if="sort_value!='asc'" @click.prevent="setSort('asc')" type="button" class="btn btn-default btn-block"><i class="fa fa-sort-alpha-asc"></i> Sort A-Z</button>
                <button title="Sort Z-A (Descending)" v-else @click.prevent="setSort('desc')" type="button" class="btn btn-default btn-block"><i class="fa fa-sort-alpha-desc"></i> Sort Z-A</button>
              </div>
              <div class="col">
                <button :disabled="user.role === 'Restricted'" class="btn btn-default btn-block" @click="addConsumer"><i  class="fa fa-plus"></i> New</button>
              </div>
            </div>

          </div>
          </div>
          <!-- /.card-tools -->
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6 d-none d-sm-block">
              <cite class="text-warning"> Criteria: Search {{this.search_value}} from the "{{this.by_value}}" list in "{{sort_value}}" order.</cite>
            </div>
            <div class="col-sm-12 col-md-6">
              <h3 class="card-title float-right">Page {{ consumers.current_page }} of {{ consumers.last_page }} ({{ consumers.total }} results)</h3>
            </div>
          </div>
        </div>
        
        <div v-if="!consumers.data || loading" class="card-body pb-0 pt-0">
          <div class="row d-flex align-items-stretch">
            <div v-for="index in 9" :key="index" class="col-12 col-sm-6 col-md-4">
              <div class="card">
                <div class="ribbon-wrapper ribbon-lg">
                  <div class="ribbon bg-secondary">
                    &nbsp;
                  </div>
                </div>
                <div class="card-header text-muted border-bottom-0">
                  <skeleton-loader-vue v-show="skeleton" class="" type="rect" :rounded="true" :width="150" :height="24" animation="fade" />
                </div>
                <div class="card-body pt-0">
                  <div class="row">
                    <div class="col-12">
                      <skeleton-loader-vue v-show="skeleton" class="mb-3" type="rect" :rounded="true" :width="220" :height="26" animation="fade" />
                      <skeleton-loader-vue v-show="skeleton" class="mb-1" type="rect" :rounded="true" :width="200" :height="15" animation="fade" />
                      <skeleton-loader-vue v-show="skeleton" class="mb-1" type="rect" :rounded="true" :width="260" :height="15" animation="fade" />
                      <skeleton-loader-vue v-show="skeleton" class="mb-1" type="rect" :rounded="true" :width="160" :height="15" animation="fade" />
                      <skeleton-loader-vue v-show="skeleton" class="mb-1" type="rect" :rounded="true" :width="180" :height="15" animation="fade" />
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="text-right">
                      <div class="row">
                        <div class="col-12">
                          <button type="button" disabled class="btn btn-outline-secondary ml-2" data-toggle="tooltip" data-container="body" title="Delete"><i class="spinner-border spinner-border-sm"></i></button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div v-else class="card-body pb-0 pt-0">
          <div v-show="consumers.total==0" class="card-body p-0">
            <div class="row justify-content-center mt-5">
                <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                  <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                    <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                    <g fill-rule="nonzero" stroke="#D9D9D9">
                      <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                      <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                    </g>
                  </g>
                </svg>                  
            </div>
            <div class="row justify-content-center mb-5 text-muted">
              <p>No records found. Please try another search.</p>
            </div>
          </div>
          <div class="row d-flex align-items-stretch">
            <div v-for="consumer in consumers.data" :key="consumer.id" class="col-12 col-sm-6 col-md-4">
              
              <div class="card">
                <div class="ribbon-wrapper ribbon-lg">
                  <div v-if="consumer.Active == 1" class="ribbon bg-success">
                    CONNECTED
                  </div>
                  <div v-else class="ribbon bg-danger">
                    DISCONNECTED
                  </div>
                </div>
                <div class="card-header text-muted border-bottom-0">
                  <b>Consumer No: </b> {{consumer.Consumercode}}
                </div>
                <div class="card-body pt-0">
                  <div class="row">
                    <div class="col-12">
                      <h3 v-if="getFullname(consumer.Lastname, consumer.Firstname, consumer.Middlename, consumer.Extension).length > 24" v-tooltip="{ content: '<b>' + getFullname(consumer.Lastname, consumer.Firstname, consumer.Middlename, consumer.Extension) + '</b>', placement: 'top', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 5, delay: { show: 500, hide: 300, }, }">
                        <b>{{ getFullname(consumer.Lastname, consumer.Firstname, consumer.Middlename, consumer.Extension) | truncate(24, '...') | bigcaps }}</b>
                      </h3>
                      <h3 v-else>
                        <b>{{ getFullname(consumer.Lastname, consumer.Firstname, consumer.Middlename, consumer.Extension) | bigcaps }}</b>
                      </h3>
                      <ul class="ml-4 mb-0 fa-ul text-muted">
                        <li v-if="consumer.Consumertype == 'Residential'" class="small text-purple"><span class="fa-li"><i class="fa fa-briefcase"></i></span> <b>{{consumer.Consumertype}} - {{consumer.Pipe}} Pipe</b></li>
                        <li v-else class="small text-maroon"><span class="fa-li"><i class="fa fa-briefcase"></i></span> <b>{{consumer.Consumertype}} - {{consumer.Pipe}} Pipe</b></li>
                        <li class="small"><span class="fa-li"><i class="fa fa-map-marker"></i></span> SERVICE LOCATION : {{consumer.Location}}</li>
                        <li class="small" v-if="consumer.Senior !== null"><span class="fa-li"><i class="fa fa-id-card-o"></i></span> SENIOR : {{consumer.Senior}}</li>
                        <li class="small" v-else><span class="fa-li"><i class="fa fa-id-card-o"></i></span> SENIOR : NA</li>
                        <li class="small" v-if="consumer.Pwd !== null"><span class="fa-li"><i class="fa fa-wheelchair"></i></span> PWD : {{consumer.Pwd}}</li>
                        <li class="small" v-else><span class="fa-li"><i class="fa fa-wheelchair"></i></span> PWD : NA</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="text-right">
                      <div class="row" v-show="user.role === 'Reader' || user.role === 'Cashier'">
                        <div class="col-12">
                          <router-link :to="{ path: '/account/' + consumer.id }" class="btn btn-sm btn-outline-primary btn-block">View Consumer Account <i class="fa fa-arrow-circle-o-right"></i></router-link>
                        </div>
                      </div>
                      <div class="row" v-show="user.role === 'Admin' || user.role === 'Supervisor'">
                        <div class="col-12">
                          <router-link :to="{ path: '/account/' + consumer.id }" class="btn btn-outline-primary float-left">View Consumer Account <i class="fa fa-arrow-circle-o-right"></i></router-link>
                          <button @click="deleteConsumer(consumer.id)" type="button" class="btn btn-outline-danger ml-2" data-toggle="tooltip" data-container="body" title="Delete"><i class="fa fa-trash"></i></button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div v-show="consumers.total!=0" class="card-footer">
          <pagination class="pagination justify-content-center m-0" :data="consumers" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
        </div>
      </div>

      <br/>


      <!-- start modal -->
      <div class="modal fade" data-backdrop="static" id="consumermodal" tabindex="-1" role="dialog" aria-labelledby="consumerModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header bg-orange">
              <h1 v-show="!editmode" class="card-title modal-title"><b>Add New Consumer</b></h1>
              <h1 v-show="editmode" class="card-title modal-title"><b>Edit Consumer</b></h1>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form @submit.prevent="editmode ? updateConsumer() : saveConsumer()" @keydown="form.onKeydown($event)" novalidate>
              <div class="modal-body">
                <div class="row-aligncenter">
                  <div class="card-body table-responsive p-0">

                    <div class="form-group">
                      <label for="inputConsumerno">Consumer Account Number</label> <span v-if="generate_loading" class="float-right text-success"><i class="spinner-border spinner-border-sm"></i> &nbsp;</span><span v-if="generate_loading" class="float-right text-primary"><i class="spinner-border spinner-border-sm"></i> &nbsp;</span><span v-if="generate_loading" class="float-right text-warning"><i class="spinner-border spinner-border-sm"></i> &nbsp;</span><span v-if="generate_loading" class="float-right text-danger"><i class="spinner-border spinner-border-sm"></i> &nbsp;</span>
                      <input type="text" v-model.lazy="$v.form.Consumercode.$model" class="form-control" id="inputConsumerno" aria-describedby="inputConsumerno" :class="{'is-invalid':$v.form.Consumercode.$invalid,'is-valid':!$v.form.Consumercode.$invalid }" readonly />
                      <!--<div class="valid-feedback"><i class="fa fa-check-circle"></i> Your last name is valid.</div>-->
                      <div class="invalid-feedback">
                          <span class="help-block" v-if="!$v.form.Consumercode.required"><i class="fa fa-exclamation-circle"></i> Please select service location to generate acct number.</span>
                          <span class="help-block" v-if="!$v.form.Consumercode.maxLength"><i class="fa fa-exclamation-circle"></i> Consumer data for this service location is full. Call administrator.</span>
                      </div>
                    </div>

                    <div class="form-group" :class="{'has-error':$v.form.Location.$error,'has-success':!$v.form.Location.$invalid }">
                      <label for="inputLocation">Service Location</label><span class="text-danger">*</span>
                      <select  v-model.lazy="$v.form.Location.$model" :class="{'is-invalid':$v.form.Location.$error,'is-valid':!$v.form.Location.$invalid }" class="form-control" id="inputLocation" aria-describedby="inputLocation" @change="generateConsumercode(form.Location)">
                        <option value="">- Select Location -</option>
                        <option value="As-is">As-is</option>
                        <option value="Balayong">Balayong</option>
                        <option value="Lynville">Lynville</option>
                        <option value="Amaia">Amaia</option>
                      </select>
                      <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form.Location.required"><i class="fa fa-exclamation-circle"></i> Service lcoation is required.</span>
                      </div>
                    </div>

                    <div class="form-group" :class="{'has-error':$v.form.Consumertype.$error,'has-success':!$v.form.Consumertype.$invalid }">
                      <label for="inputConsumerType">Consumer Type</label><span class="text-danger">*</span>
                      <select  v-model.lazy="$v.form.Consumertype.$model" :class="{'is-invalid':$v.form.Consumertype.$error,'is-valid':!$v.form.Consumertype.$invalid }" class="form-control" id="inputConsumerType" aria-describedby="inputConsumerType">
                        <option value="">- Select Consumer Type -</option>
                        <option value="Residential">Residential</option>
                        <option value="Commercial/Industrial">Commercial/Industrial</option>
                      </select>
                      <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form.Consumertype.required"><i class="fa fa-exclamation-circle"></i> Consumer type is required.</span>
                      </div>
                    </div>

                    <div class="form-group" :class="{'has-error':$v.form.Pipe.$error,'has-success':!$v.form.Pipe.$invalid }">
                      <label for="inputPipe">Pipe Size</label><span class="text-danger">*</span>
                      <select  v-model.lazy="$v.form.Pipe.$model" :class="{'is-invalid':$v.form.Pipe.$error,'is-valid':!$v.form.Pipe.$invalid }" class="form-control" id="inputPipe" aria-describedby="inputPipe">
                        <option value="">- Select Pipe Size -</option>
                        <option value='1/2"'>1/2"</option>
                        <option value='2"'>2"</option>
                      </select>
                      <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form.Pipe.required"><i class="fa fa-exclamation-circle"></i> Pipe size is required.</span>
                      </div>
                    </div>

                    <div class="form-group" :class="{'has-error':$v.form.Firstname.$error,'has-success':!$v.form.Firstname.$invalid }">
                      <label for="inputFirstname">First Name</label><span class="text-danger">*</span>
                      <input @keydown.enter="focusNext" type="text"  v-model.lazy="$v.form.Firstname.$model" class="form-control" id="inputFirstname" aria-describedby="inputFirstname" :class="{'is-invalid':$v.form.Firstname.$error,'is-valid':!$v.form.Firstname.$invalid }" placeholder="First name" autocomplete="off" />
                      <div class="invalid-feedback">
                          <span class="help-block" v-if="!$v.form.Firstname.required"><i class="fa fa-exclamation-circle"></i> First name is required.</span>
                          <span class="help-block" v-if="!$v.form.Firstname.minLength"><i class="fa fa-exclamation-circle"></i> First name must have at least {{$v.form.Firstname.$params.minLength.min}} characters.</span>
                          <span class="help-block" v-if="!$v.form.Firstname.maxLength"><i class="fa fa-exclamation-circle"></i> First name must not exeed to {{$v.form.Firstname.$params.maxLength.max}} characters.</span>
                      </div>
                    </div>

                    <div class="form-group" :class="{'has-error':$v.form.Lastname.$error,'has-success':!$v.form.Lastname.$invalid }">
                      <label for="inputLastname">Last Name</label><span class="text-danger">*</span>
                      <input @keydown.enter="focusNext" type="text" v-model.lazy="$v.form.Lastname.$model" class="form-control" id="inputLastname" aria-describedby="inputLastname" :class="{'is-invalid':$v.form.Lastname.$error,'is-valid':!$v.form.Lastname.$invalid }" placeholder="Last name" autocomplete="off" />
                      <div class="invalid-feedback">
                          <span class="help-block" v-if="!$v.form.Lastname.required"><i class="fa fa-exclamation-circle"></i> Last name is required.</span>
                          <span class="help-block" v-if="!$v.form.Lastname.minLength"><i class="fa fa-exclamation-circle"></i> Last name must have at least {{$v.form.Lastname.$params.minLength.min}} characters.</span>
                          <span class="help-block" v-if="!$v.form.Lastname.maxLength"><i class="fa fa-exclamation-circle"></i> Last name must not exeed to {{$v.form.Lastname.$params.maxLength.max}} characters.</span>
                      </div>
                    </div>

                    <div class="form-group" :class="{'has-error':$v.form.Middlename.$error,'has-success':!$v.form.Middlename.$invalid }">
                      <label for="inputMiddlename">Middle Name</label><small class="text-info"> (optional)</small>
                      <input @keydown.enter="focusNext" type="text" v-model.lazy="$v.form.Middlename.$model" :class="{'is-invalid':$v.form.Middlename.$error,'is-valid':!$v.form.Senior.$invalid }" class="form-control" id="inputMiddlename" aria-describedby="inputMiddlename" placeholder="Middle name" autocomplete="off" />
                      <div class="invalid-feedback">
                          <span class="help-block" v-if="!$v.form.Middlename.maxLength"><i class="fa fa-exclamation-circle"></i> Middle name must not exeed to {{$v.form.Senior.$params.maxLength.max}} characters.</span>
                      </div>
                    </div>

                    <div class="form-group" :class="{'has-error':$v.form.Extension.$error,'has-success':!$v.form.Extension.$invalid }">
                      <label for="inputExtension">Extension</label><small class="text-info"> (optional)</small>
                      <input @keydown.enter="focusNext" type="text" v-model.lazy="$v.form.Extension.$model" :class="{'is-invalid':$v.form.Extension.$error,'is-valid':!$v.form.Extension.$invalid }" class="form-control" id="inputExtension" aria-describedby="inputExtension" placeholder="Extension name" autocomplete="off" />
                      <div class="invalid-feedback">
                          <span class="help-block" v-if="!$v.form.Extension.maxLength"><i class="fa fa-exclamation-circle"></i> Extension name must not exeed to {{$v.form.Extension.$params.maxLength.max}} characters.</span>
                      </div>
                    </div>

                    <div class="form-group" :class="{'has-error':$v.form.Address.$error,'has-success':!$v.form.Address.$invalid }">
                      <label for="inputAddress">Permanent Address</label><span class="text-danger">*</span>
                      <input @keydown.enter="focusNext" type="text" v-model.lazy="$v.form.Address.$model" class="form-control" id="inputAddress" aria-describedby="inputAddress" :class="{'is-invalid':$v.form.Address.$error,'is-valid':!$v.form.Address.$invalid }" placeholder="Permanent Address" autocomplete="off" />
                      <div class="invalid-feedback">
                          <span class="help-block" v-if="!$v.form.Address.required"><i class="fa fa-exclamation-circle"></i> Address is required.</span>
                          <span class="help-block" v-if="!$v.form.Address.minLength"><i class="fa fa-exclamation-circle"></i> Address must have at least {{$v.form.Address.$params.minLength.min}} characters.</span>
                          <span class="help-block" v-if="!$v.form.Address.maxLength"><i class="fa fa-exclamation-circle"></i> Address must not exeed to {{$v.form.Address.$params.maxLength.max}} characters.</span>
                      </div>
                    </div>

                    <div class="form-group" :class="{'has-error':$v.form.Senior.$error,'has-success':!$v.form.Senior.$invalid }">
                      <label for="inputSenior">Senior Citizen ID</label><small class="text-info"> (optional)</small>
                      <input @keydown.enter="focusNext" type="text" v-model.lazy="$v.form.Senior.$model" :class="{'is-invalid':$v.form.Senior.$error,'is-valid':!$v.form.Senior.$invalid }" class="form-control" id="inputSenior" aria-describedby="inputSenior" placeholder="Senior Citizen ID" autocomplete="off" />
                      <div class="invalid-feedback">
                          <span class="help-block" v-if="!$v.form.Senior.maxLength"><i class="fa fa-exclamation-circle"></i> Senior citizen ID must not exeed to {{$v.form.Senior.$params.maxLength.max}} characters.</span>
                      </div>
                    </div>

                    <div class="form-group" :class="{'has-error':$v.form.Pwd.$error,'has-success':!$v.form.Pwd.$invalid }">
                      <label for="inputPwd">PWD ID</label><small class="text-info"> (optional)</small>
                      <input @keydown.enter="focusNext" type="text" v-model.lazy="$v.form.Pwd.$model" :class="{'is-invalid':$v.form.Pwd.$error,'is-valid':!$v.form.Pwd.$invalid }" class="form-control" id="inputPwd" aria-describedby="inputPwd" placeholder="PWD ID" autocomplete="off" />
                      <div class="invalid-feedback">
                          <span class="help-block" v-if="!$v.form.Pwd.maxLength"><i class="fa fa-exclamation-circle"></i> PWD ID must not exeed to {{$v.form.Pwd.$params.maxLength.max}} characters.</span>
                      </div>
                    </div>

                    <div class="form-group clearfix">
                      <div class="icheck-primary d-inline">
                        <input type="checkbox" id="checkboxPrimary1" v-model="confirm">
                        <label for="checkboxPrimary1">
                          Confirm to submit
                        </label>
                      </div>
                    </div>

                  </div>
              
                </div>
              </div>
              <div class="card-footer">
                <div class="row col-12 p-0 m-0">
                    <div class="col-8 p-0">
                      
                    </div>
                    <div class="text-right col-12 p-0">
                      
                      <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>&nbsp;
                      <button v-show="!editmode" type="submit" :disabled="!confirm || $v.$invalid" class="btn btn-info" ><i class="fa fa-chevron-circle-right"></i> Submit</button>
                    </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
      <!-- end modal -->

    </section>

  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'
import Form from 'vform'
import Loader from '@/components/Loader'
import { required, minLength, maxLength, sameAs, not } from 'vuelidate/lib/validators'
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'Consumers',

  components: {
    Loader, VueSkeletonLoader,
  },

  data() {
    return {
      editmode: false,
      confirm : false,
      loading: false,
      skeleton: true,
      generate_loading: false,
      consumers : {},
      isloaded: false,
      search_value: '',
      by_value: 'Consumer Number',
      sort_value: 'asc',
      error: '',
      form: new Form({
        Consumercode: '',
        Lastname: '',
        Firstname:'',
        Middlename: '',
        Extension: '',
        Address: '',
        Senior:'',
        Pwd: '',
        Consumertype: '',
        Pipe: '',
        Location: '',
      })
    }
  },

  validations: {
    form: {
      Consumercode: { required, maxLength: maxLength(8) },
      Lastname: { required,  minLength: minLength(2),  maxLength: maxLength(60) },
      Firstname: { required,  minLength: minLength(2),  maxLength: maxLength(60) },
      Middlename: { maxLength: maxLength(6) },
      Extension: { maxLength: maxLength(10) },
      Address: { required,  minLength: minLength(2),  maxLength: maxLength(150) },
      Senior: { maxLength: maxLength(15) },
      Pwd: { maxLength: maxLength(15) },
      Consumertype: { required },
      Pipe: { required },
      Location: { required },
    }
  },

  methods: {

    getFullname(lastname, firstname, middlename, extension) {
      var mname = '';
      var ext = '';
      var fname = '';

      if (!firstname) {
        fname = '';
      } else {
        fname = ', ' + firstname;
      }

      if (middlename) {
        mname = ' ' + middlename.substring(0, 1) + '.';
      }

      if (extension) {
        ext = ' ' + extension;
      }
      
      return lastname + ext + fname + mname;
    },

    setSort(val) {
      this.sort_value = val;
      this.getResults();
    },

    setBy(val) {
      this.by_value = val;
      this.getResults();
    },

    toggleToast() {
      this.$swal.fire({
        icon: 'info',
        title: 'Setup this view first'
      })
    },

    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input'));
      const index = inputs.indexOf(e.target);

      if (index < inputs.length) {
          inputs[index + 1].focus();
      }
    },

    async generateConsumercode(location) {
      this.generate_loading= true;
      
      var loc;
      var prefix;
      var code;
      var masked_code;

      if(location == 'As-is') {
        loc = 'A';
      } else if (location == 'Balayong') {
        loc = 'B';
      } else if (location == 'Lynville') {
        loc = 'L';
      } else if (location == 'Amaia') {
        loc = 'AM';
      }

      if(!location) {
        this.generate_loading = false;
      } else {
        this.generate_loading = true;
        await axios.get('api/consumers/generate/consumercode/' + loc).then(response => {
          if (!response.data) {
            this.form.Consumercode = loc + '000001';   
          } else {
            prefix = response.data.Consumercode.slice(0, -6);
            code = response.data.Consumercode.slice(response.data.Consumercode.length - 6);
            code = parseInt(code) + 1

            if (code > 999999) {
              this.form.Consumercode = 'Consumer block is full';
            } else {
              masked_code = this.zeroPad(code, 6);
              this.form.Consumercode = prefix + masked_code;
            }
            //console.log(prefix + '-' + masked_code); 
          }
        });
      }
      this.generate_loading = false;
    },

    zeroPad(num, places) {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    },

    saveConsumer() {
      this.$v.$touch();
      this.$Progress.start();
      if(!this.$v.$invalid) {
        try {
          
          this.form.post('api/consumers')
          .then(()=>{
                this.$swal.fire({
                  icon: 'success',
                  title: 'New consumer saved'
                })
                this.$Progress.finish();
                this.loadData();
                $('#consumermodal').modal('hide')
          }).catch(()=>{
                this.$swal.fire({
                  icon: 'error',
                  title: 'New consumer failed'
                });
                this.$Progress.fail();
          })
        } catch (e) {
            this.error = e.response.data.message;
            this.$Progress.fail();
        }
      }
      this.$v.$reset();
    },

    updateConsumer() {
      this.$v.$touch();
      if(!this.$v.$invalid) {
        try {
          console.log('hoorah!');
        } catch (e) {
            this.error = e.response.data.message;
        }
      }
    },

    deleteConsumer(id) {
      swal.fire({

        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33', 
        confirmButtonText: 'Yes, delete this consumer'
        }).then((result)=>{
          if (result.value) {
            this.form.delete('api/consumers/'+id).then(()=>{
              swal.fire(
                'Deleted!',
                'Consumer has been deleted.',
                'success'
              );
              this.getResults();
            }).catch(()=>{
              swal.fire(
                'Failed!', 
                'There was something wrong', 
                'warning'
              )
            });
          }
      })
    },

    editConsumer(consumer) {
      this.editmode = true;
      this.form.reset();
      this.form.clear();
      this.form.fill(consumer);
      $('#consumermodal').modal('show');
    },

    addConsumer() {
      this.$v.$reset();
      this.editmode = false;
      this.form.reset();
      this.form.clear();
      $('#consumermodal').modal('show');
    },

    async getResults(page = 1) {
      this.loading = true;
      this.skeleton = true;
      let query = this.search_value;
      let sort = this.sort_value;
      let field = this.by_value;
      let by = '';

      if (field === 'Last Name') {
        by = 'Lastname'
      } else if (field === 'First Name') {
        by = 'Firstname'
      } else if (field === 'Consumer Number') {
        by = 'Consumercode'
      } else if (field === 'Location') {
        by = 'Location'
      }

      if(query == '') {
        await axios.get('api/consumers/filter?sort=' + sort + '&by=' + by +'&page=' + page).then(response => { this.consumers = response.data; });
      } else {
        await axios.get('api/consumers/filter?sort=' + sort + '&search=' + query + '&by=' + by +'&page=' + page).then(response => { this.consumers = response.data; }); 
      }
      this.loading = false;
      this.skeleton = false;
    },

    async loadData() {
      this.loading = true;

      await axios.get('api/consumers').then(({ data }) => (this.consumers = data));
      this.loading = false;
      this.skeleton = false;
    }
  },

  computed: {
    ...mapGetters(['user']),
  },

  mounted() {
    this.$Progress.start();
    this.loadData();
    this.$Progress.finish();
    //this.$refs.search.focus();
  }

}
</script>

<style scoped>
.pagination {
  margin: 0;
}
.ddown {
  cursor: pointer;
}
</style>
